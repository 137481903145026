@media (prefers-color-scheme: dark) {
  body {
    background-color: #001214;
    padding-top: 64px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .App {
    text-align: center;
  }
  .App-Avatar {
    height: 40vmin;
    border-radius: 50%;
    border-style: solid;
    border-width: medium;
    border-color: white;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #61dafb;
  }
  .App-Text {
    color: white;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: white;
  }
  .App {
    text-align: center;
  }
  .App-Avatar {
    height: 40vmin;
    border-radius: 50%;
    border-style: solid;
    border-width: medium;
    border-color: black;
  }
  .App-header {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  .App-link {
    color: #61dafb;
  }
  .App-Text {
    color: black;
    padding-left: 24px;
    padding-right: 24px;
  }
}
